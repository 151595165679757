var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"text-right d-md-block d-none",attrs:{"cols":"8"}},[(_vm.$acl.can('customerCreateEdit'))?_c('v-btn',{staticClass:"text-capitalize",attrs:{"bottom":"","large":"","color":"primary"},on:{"click":function($event){return _vm.handleNewCustomer()}}},[_vm._v(" Novo ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers.data,"items-per-page":20,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.customers.total,"hide-default-footer":"","disable-sort":""},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.handleTableUpdate($event)}],"click:row":function($event){return _vm.handleNewCustomer($event.id)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('CustomerLabel',{attrs:{"customer":item,"hideLink":true}})]}},{key:"item.doc",fn:function(ref){
var item = ref.item;
return [(item.person_type == 'fisica')?_c('span',[_vm._v(_vm._s(_vm.$format.cpf(item.cpf)))]):_vm._e(),(item.person_type == 'juridica')?_c('span',[_vm._v(_vm._s(_vm.$format.cnpj(item.cnpj))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$acl.can('customerCreditAnalysis'))?_c('v-list-item',{on:{"click":function($event){return _vm.handleCreditAnalysis(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cash-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Analise de Crédito")])],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.customers},on:{"click":function($event){return _vm.select($event)}}})],1)],1),_c('StoreCustomerForm',{ref:"StoreCustomerForm",on:{"store":function($event){return _vm.select()}}}),_c('CustomerCreditAnalysisDialog',{ref:"CustomerCreditAnalysisDialog",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }